import { template as template_84349a73b9a448f29ccf27dbc3e11ad3 } from "@ember/template-compiler";
const FKLabel = template_84349a73b9a448f29ccf27dbc3e11ad3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
