import { template as template_20ed70eabefa41c3a9df1bc82e4bd165 } from "@ember/template-compiler";
const SidebarSectionMessage = template_20ed70eabefa41c3a9df1bc82e4bd165(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
