import { template as template_a7a3a5c9ee0048c68b36d24e0a4ec071 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a7a3a5c9ee0048c68b36d24e0a4ec071(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
