import { template as template_09c3a9c1e33e49d4ae234e30c2bdd3b5 } from "@ember/template-compiler";
import { notEq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
const FKControlConditionalContentItem = template_09c3a9c1e33e49d4ae234e30c2bdd3b5(`
  <div
    class={{concatClass
      "form-kit__conditional-display-content"
      (if (notEq @name @activeName) "hidden")
    }}
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
